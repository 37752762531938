import React, { useEffect, useState } from "react";
import kitten from "../../assets/kitten.gif"; // 🐱 Assure-toi d’avoir une image de chaton ici
import './GestationTracker.css';

const expectedDate = new Date("2025-05-18");
const startDate = new Date("2025-03-15");
const gestationDays = 63;

const gestationPhases = [
  { semaine: "Semaine 1", dates: "15 – 21 mars", description: "Fécondation – ovules fécondés se fixent à l’utérus", startDay: 0 },
  { semaine: "Semaine 2", dates: "22 – 28 mars", description: "Début de la gestation – peu de signes visibles", startDay: 7 },
  { semaine: "Semaine 3", dates: "29 mars – 4 avril", description: "Mamelons rosissent et gonflent légèrement", startDay: 14 },
  { semaine: "Semaine 4", dates: "5 – 11 avril", description: "Appétit accru, légères nausées possibles", startDay: 21 },
  { semaine: "Semaine 5", dates: "12 – 18 avril", description: "Ventre commence à s’arrondir", startDay: 28 },
  { semaine: "Semaine 6", dates: "19 – 25 avril", description: "Activité des chatons perceptible", startDay: 35 },
  { semaine: "Semaine 7", dates: "26 avril – 2 mai", description: "Préparation du nid", startDay: 42 },
  { semaine: "Semaine 8", dates: "3 – 9 mai", description: "Montée de lait", startDay: 49 },
  { semaine: "Semaine 9", dates: "10 – 16 mai", description: "Repos fréquent", startDay: 56 },
  { semaine: "Mise bas attendue", dates: "17 – 20 mai", description: "Naissance imminente !", startDay: 63 },
];

export default function GestationTracker() {
  const [message, setMessage] = useState("");
  const [progress, setProgress] = useState(10); // Par défaut, 10% pour tester la barre
  const [currentDay, setCurrentDay] = useState(0);

  useEffect(() => {
    const today = new Date();
    const daysLeft = Math.ceil((expectedDate - today) / (1000 * 60 * 60 * 24));
    const daysPassed = Math.max(0, Math.floor((today - startDate) / (1000 * 60 * 60 * 24)));
    const percentage = Math.min(100, Math.round((daysPassed / gestationDays) * 100));
    setProgress(percentage);
    setCurrentDay(daysPassed);

    if (daysLeft > 30) {
      setMessage(`Gestation en cours : ${daysLeft} jours restants avant la mise bas.`);
    } else if (daysLeft <= 30 && daysLeft > 7) {
      setMessage(`⚠️ Mise bas prévue dans environ ${daysLeft} jours. Préparez le nid !`);
    } else if (daysLeft <= 7 && daysLeft > 1) {
      setMessage(`🚨 Mise bas imminente : J-${daysLeft} !`);
    } else if (daysLeft === 1) {
      setMessage(`🔥 Dernier jour avant la date prévue !`);
    } else if (daysLeft === 0) {
      setMessage(`🎉 C’est aujourd’hui !`);
    } else {
      setMessage(`✅ Mise bas théoriquement passée !`);
    }
  }, []);

  const getCurrentStepIndex = () => {
    return gestationPhases.findLastIndex(phase => currentDay >= phase.startDay);
  };

  const currentStep = getCurrentStepIndex();

  return (
    <div className="max-w-4xl mx-auto p-6 bg-white rounded-2xl shadow-xl border border-gray-100 relative overflow-hidden">
      <h2 className="text-2xl font-bold text-center mb-4 text-pink-600">Suivi de la gestation de Lili</h2>
      <div className="text-center mb-4 text-gray-800 font-medium">{message}</div>

      {/* Barre de progression */}
      <div className="progress-bar-container">
        <div
          className="progress-bar"
          style={{ width: `${progress}%`, backgroundColor: '#e91e63' }}
        ></div>
      </div>

      {/* 🎉 Chaton animé à 100% */}
      {progress === 100 && (
        <div className="flex justify-center mb-6 animate-bounce">
          <img src={kitten} alt="Chaton né !" className="w-32 h-32 object-contain" />
        </div>
      )}

      {/* Tableau de suivi */}
      <div className="overflow-x-auto">
        <table className="w-full text-sm text-left text-gray-700 border">
          <thead className="bg-pink-50 text-pink-700">
            <tr>
              <th className="px-4 py-2 border"> </th>
              <th className="px-4 py-2 border">Période</th>
              <th className="px-4 py-2 border">Jour approximatif</th>
              <th className="px-4 py-2 border">Développement / Comportement</th>
            </tr>
          </thead>
          <tbody>
            {gestationPhases.map((phase, index) => (
              <tr
                key={index}
                className={`even:bg-gray-50 ${
                  index === currentStep ? "bg-yellow-100 border-l-4 border-pink-500" : ""
                }`}
              >
                <td className="px-2 py-2 border text-center current-arrow">
                  {index === currentStep ? "→" : ""}
                </td>
                <td className={`px-4 py-2 border ${index === currentStep ? "current-week" : ""}`}>{phase.semaine}</td>
                <td className="px-4 py-2 border">{phase.dates}</td>
                <td className="px-4 py-2 border">{phase.description}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}
